import React, {Component} from "react";
import {withNamespaces as translate} from "react-i18next";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Message} from "primereact/message";
import {PropTypes} from "prop-types";
import logo from "./assets/logo.svg";
import logoSmartview from "./assets/logo-smartview.svg";
import config from "./config";
import {request} from "./util/RequestUtil";
import {Card} from "primereact/card";
import {Store} from "./store/Store";
import SessionAction from "./store/session/SessionAction";
import ChangeType from "./store/changeType/ChangeType";
import moment from "moment";
import {ProgressSpinner} from "primereact/progressspinner";
import MessagesUtil from "./util/MessagesUtil";
import ErrorUtil from "./util/ErrorUtil";
import SystemStatusRequest from "./requests/SystemStatusRequest";

class Login extends Component {
    static propTypes = {
        t: PropTypes.func,
        error: PropTypes.string,
        onSuccess: PropTypes.func,
        isSmartView: PropTypes.bool
    };

    static defaultProps = {
        error: ""
    };

    // #region  Set-Up Lifecycle
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: "",
            email: "",
            password: "",
            systemState: null
        };

        this.submitForm = this.submitForm.bind(this);
        this.systemState = this.systemState.bind(this);
    }

    componentDidMount() {
        const jwt = localStorage.getItem("token");
        if (jwt) {
            this.setState({loading: true});
            try {
                const res = request("/user." + config.STACK_NAME + "/login", {
                    headers: {
                        Authorization: "Bearer " + jwt
                    }
                });
                res.json();
                this.setState({loading: false, error: ""});
                Store.dispatch(
                    SessionAction.create(ChangeType.UPDATE, SessionAction.LOGIN, {
                        jwt: jwt
                    })
                );
            } catch (e) {
                if (e === "unauthorized") {
                    this.setState({loading: false});
                } else {
                    this.setState({
                        loading: false,
                        error: "txt_unauthorizedLogout"
                    });
                    localStorage.removeItem("token");
                }
            }
        }

        const systemState = this.state.systemState;
        if (systemState === null) {
            this.systemState();
        }
    }

    // #endregion

    systemState() {
        this.setState({
            systemState: "unknown"
        });
        SystemStatusRequest.loadStatus()
            .then((result) => {
                this.setState({
                    systemState: result.payload
                });
            })
            .catch((err) => {
                ErrorUtil.handleError("msg_loadSystemStateFailed", err, false);
                this.setState({systemState: "unknown"});
            });
    }

    stateColor = () => {
        switch (this.state.systemState) {
            case "error": {
                return "red";
            }
            case "warning": {
                return "orange";
            }
            case "healthy": {
                return "green";
            }
            default: {
                return "lightgray";
            }
        }
    };

    // #region Store Callback
    // #endregion

    // #region UI Callbacks
    async resolveError(res) {
        let err;
        if (res.json) {
            try {
                err = await res.json();
            } catch (_) {
                err = res;
            }
        } else {
            err = res;
        }
        let error;
        if (err.error) {
            error = err.error;
        } else {
            error = "txt_unknownError";
        }
        console.error(err, error);
        return error;
    }

    async submitForm(e) {
        e.preventDefault();
        this.setState({loading: true, error: ""});
        try {
            const res = await fetch(config.API_URL + "/user." + config.STACK_NAME + "/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            });
            if (!res.ok) {
                throw res;
            }
            const json = await res.json();
            if (json.error || !json.jwt) {
                throw json;
            }

            this.setSuccessful(json);
        } catch (e) {
            console.error(e);
            this.setState({error: await this.resolveError(e), loading: false});
        }
    }

    resetPassword = () => {
        const crypto = require("crypto");
        const cipher = crypto.createCipheriv(
            "aes-256-cbc",
            Buffer.from(config.CHIPER_KEY),
            config.CHIPER_IV
        );
        let encrypted = cipher.update(this.state.email, "utf-8", "base64");
        encrypted += cipher.final("base64");

        fetch(config.API_URL + "/core." + config.STACK_NAME + "/tasks/requestPasswordChange", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: encrypted
            })
        })
            .then((response) => {
                if (response.ok) {
                    MessagesUtil.send(
                        MessagesUtil.TYPES.GLOBAL,
                        MessagesUtil.LEVELS.SUCCESS,
                        "msg_resetPasswordRequestSuccess"
                    );
                } else {
                    ErrorUtil.handleError(
                        "msg_resetPasswordRequestFalied",
                        response.statusText,
                        true
                    );
                }
            })
            .catch((error) => ErrorUtil.handleError("msg_resetPasswordRequestFalied", error, true));
    };

    // #endregion

    // #region Class Functions
    setSuccessful(data) {
        this.setState({email: "", password: ""});
        Store.dispatch(
            SessionAction.create(ChangeType.UPDATE, SessionAction.LOGIN, {
                jwt: data.jwt
            })
        );
    }

    // #endregion

    // #region Render Function
    render() {
        const loginError = this.state.error || this.props.error;
        const header = (
            <img
                alt="Lkw-Walter Logo"
                src={this.props.isSmartView ? logoSmartview : logo}
                className="f-login-logo"
            />
        );
        const footer = (
            <div>
                <Button type="submit" onClick={this.submitForm} label={"Login"} />
                <hr style={{marginTop: "1em"}} />
                <div>
                    <span
                        onClick={this.state.email ? this.resetPassword : null}
                        style={this.state.email ? {cursor: "pointer"} : {color: "grey"}}
                    >
                        {this.props.t("gen_resetPassword")}
                    </span>
                </div>
            </div>
        );

        return (
            <div className="login-body">
                <div className="f-login-background" />
                <div className="login-panel p-fluid">
                    <form>
                        <Card className="f-login-card" header={header} footer={footer}>
                            {this.state.loading && <ProgressSpinner />}

                            <div className="p-col" style={{marginTop: "4em"}}>
                                {loginError !== "" && (
                                    <Message severity="error" text="Error Logging in" />
                                )}
                            </div>
                            <div className="p-col" style={{paddingTop: "2em"}}>
                                <span className="p-float-label">
                                    <InputText
                                        required
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                    />
                                    <label>{"Email"}*</label>
                                </span>
                            </div>
                            <div className="p-col" style={{paddingTop: "2em"}}>
                                <span className="p-float-label">
                                    <InputText
                                        type="password"
                                        required
                                        value={this.state.password}
                                        onChange={(e) =>
                                            this.setState({
                                                password: e.target.value
                                            })
                                        }
                                    />
                                    <label>{"Password"}*</label>
                                </span>
                            </div>
                        </Card>
                    </form>
                </div>
                <div className="f-login-footer">
                    <div className="f-debug">
                        <div
                            className="f-system-state"
                            style={{
                                backgroundColor: this.stateColor() || "none"
                            }}
                        />
                        <div>
                            LKW-Walter FLEET by DKV Mobility LIVE <big>©</big> {moment().year()}{" "}
                            -All Rights Reserved
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // #endregion

    // #region Render Co-Functions
    // #endregion

    // #region Tear-Down Lifecycle
    // #endregion
}

export default translate("fleet")(Login);
