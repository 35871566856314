//@ts-check
import React from "react";
import {withNamespaces as translate} from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import {observeStore, sessionSelectionFunction} from "./util/StoreUtil";
import ChangeType from "./store/changeType/ChangeType";
import {Store} from "./store/Store";
import SessionAction from "./store/session/SessionAction";
import SessionDefinition from "./store/session/SessionDefinition";
import {Features, hasPermission, isJWTDev, Permissions} from "./util/PermissionUtil";
import SessionLanguageSelector from "./components/common/fields/SessionLanguageSelector";
import config from "./config";
import TaskManager from "./components/task/TaskManager";
// import {ProgressSpinner} from "primereact/progressspinner";
import DateUtil from "./util/DateUtil";
import ProfileDialog from "./components/common/dialogs/DialogProfile";
import {AppMode} from "./AppMode";
import TasksRequest from "./requests/TasksRequest";
import ErrorUtil from "./util/ErrorUtil";
import RedirectUtil from "./util/RedirectUtil";
import ChatRequest from "./requests/ChatRequest";
import UriList from "./service/EndpointsList";

class AppTopbar extends React.Component {
    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        topbarMenuActive: false,
        activeTopbarItem: null,
        onRightPanelButtonClick: null,
        onChangePasswordItemClick: null,
        onBackToDesktopItemClick: null,
        onReportBugItemClick: null,
        isSmartView: false
    };

    static propTypes = {
        t: PropTypes.func,
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        onRightPanelButtonClick: PropTypes.func.isRequired,
        onChangePasswordItemClick: PropTypes.func.isRequired,
        onBackToDesktopItemClick: PropTypes.func.isRequired,
        onReportBugItemClick: PropTypes.func.isRequired,
        isSmartView: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            userEmail: "",
            userRole: 0,
            userUUID: "",
            userName: "",
            socket: null,
            mgmtTasks: [],
            loadingNewTasks: false,
            loadingNewChats: false,
            loadingNewPMChats: false,
            chatCount: 0,
            pmChatCount: 0
        };
        this.store = observeStore(sessionSelectionFunction, this.onStoreChange, ChangeType.UPDATE);
        this.taskStore = observeStore(
            (state) => state.tasks,
            (newState) => this.setState({task: {...newState}}),
            ChangeType.UPDATE
        );
    }

    componentDidMount() {
        this.interval = setInterval(this.wsConnect, 2000);
        this.interval = setInterval(this.wsPing, 5000);
    }

    wsPing = () => {
        if (this.socket && this.wsState() === "OPEN") {
            this.socket.send(
                JSON.stringify({
                    msg: "ping",
                    client: {
                        UUID: this.state.userUUID
                    }
                })
            );
        }
    };

    wsConnect = () => {
        const wsState = this.wsState();
        console.log("ws state: " + wsState);
        if (this.socket === undefined || wsState === "CLOSED") {
            this.socket = new WebSocket(config.WS_URL + UriList.WS_CORE);
            this.socket.onopen = () => {
                this.socket.send(
                    JSON.stringify({
                        msg: "connect",
                        client: {
                            UUID: this.state.userUUID,
                            Role: this.state.userRole
                        }
                    })
                );
            };
            this.socket.onmessage = (e) => {
                const msg = e.data ? JSON.parse(e.data) : "";
                switch (msg.msg) {
                    case "tasksReload":
                        if (!this.state.loadingNewTasks) {
                            this.setState({loadingNewTasks: true}, () => this.setTasks());
                        } else {
                            console.warn("taskReload skip, already loading");
                        }
                        break;
                    case "chatReload":
                        if (!this.state.loadingNewChats) {
                            this.setState({loadingNewChats: true}, () => this.setChats());
                        } else {
                            console.warn("chatReload skip, already loading");
                        }
                        break;
                    case "pmChatReload":
                        if (!this.state.loadingNewPMChats) {
                            this.setState({loadingNewPMChats: true}, () => this.setPMChats());
                        } else {
                            console.warn("chatPMReload skip, already loading");
                        }
                        break;
                    case "pong":
                        break;
                    default:
                }
            };
        }
    };

    wsState() {
        if (this.socket) {
            switch (this.socket.readyState) {
                case 0:
                    this.setState({
                        wsStateColor: "yellow",
                        wsState: "CONNECTING"
                    });
                    break;
                case 1:
                    this.setState({
                        wsStateColor: "white",
                        wsState: "OPEN"
                    });
                    break;
                case 2:
                    this.setState({
                        wsStateColor: "orange",
                        wsState: "CLOSING"
                    });
                    break;
                default:
                    this.setState({
                        wsStateColor: "red",
                        wsState: "CLOSED"
                    });
            }
        }
        return this.state.wsState;
    }

    setTasks() {
        this.loadTasks().then((data) => {
            this.setState({
                mgmtTasks: data,
                loadingNewTasks: false
            });
        });
    }

    setChats() {
        this.loadChats().then((data) => {
            this.setState({
                chats: data,
                loadingNewChats: false,
                chatCount: data && data.length > 0 ? data.length : 0
            });
        });
    }

    setPMChats() {
        this.loadPMChats().then((data) => {
            this.setState({
                pmChats: data,
                loadingNewPMChats: false,
                pmChatCount: data && data.length > 0 ? data.length : 0
            });
        });
    }

    countChats() {
        return this.state.chatCount + this.state.pmChatCount;
    }

    // eslint-disable-next-line consistent-return
    async loadTasks() {
        try {
            return await TasksRequest.loadNewTasks();
        } catch (err) {
            ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
        }
    }

    // eslint-disable-next-line consistent-return
    async loadChats() {
        try {
            return await ChatRequest.loadNewChats(6, this.state.userUUID, this.state.userRole);
        } catch (err) {
            ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
        }
    }

    // eslint-disable-next-line consistent-return
    async loadPMChats() {
        try {
            return await ChatRequest.loadNewPVMChats(6, this.state.userUUID, this.state.userRole);
        } catch (err) {
            ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
        }
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    onLogoutItemClick() {
        Store.dispatch(
            SessionAction.create(
                ChangeType.UPDATE,
                SessionAction.LOGOUT,
                SessionDefinition.defaultState
            )
        );
    }

    onStoreChange = (state) => {
        if (state !== null && state.jwt !== undefined) {
            console.log("onStoreChange: " + state.lastname);
            this.setState({
                userEmail: state.email,
                userRole: state.role,
                userUUID: state.user,
                userName: (state.lastname || "") + (state.firstname ? " " + state.firstname : "")
            });
        }
    };

    onMgmtTaskClick = (task) => {
        RedirectUtil.openInNewWindow(
            RedirectUtil.pathList.DATA_MANAGEMENT_TASKS,
            task.internalID,
            RedirectUtil.ACTIONTYPE.EDIT
        );
    };

    onChatClick = (chat) => {
        const loc = window.location.href;
        let uuid;
        let path;
        switch (chat.reference) {
            case "order":
                path = RedirectUtil.pathList.LKWWALTER_ORDER_DETAIL;
                uuid = chat.conversationUUID;
                break;
            case "ticket":
                path = RedirectUtil.pathList.LKWWALTER_TICKET_DETAIL;
                uuid = chat.conversationUUID;
                break;
            case "pvm-order":
                path = RedirectUtil.pathList.PARTNER_ORDER_DETAIL;
                uuid = chat.refID;
                break;
            case "pvm-ticket":
                path = RedirectUtil.pathList.PARTNER_TICKET_DETAIL;
                uuid = chat.refID;
                break;
            default:
                return;
        }
        if (loc.includes(uuid)) {
            RedirectUtil.redirect(path, uuid, RedirectUtil.ACTIONTYPE.CHAT);
        } else {
            RedirectUtil.openInNewWindow(path, uuid, RedirectUtil.ACTIONTYPE.CHAT);
        }
    };

    taskCount() {
        let count = 0;
        if (this.state.task && this.state.task.activeTasks) {
            count += this.state.task.activeTasks;
        }
        if (this.state.mgmtTasks) {
            if (this.state.mgmtTasks.length > 0) {
                count += this.state.mgmtTasks.length;
            }
        }
        return count;
    }

    render() {
        const topbarItemsClassName = classNames("topbar-items animated fadeInDown", {
            "topbar-items-visible": this.props.topbarMenuActive
        });

        const activeTasks = TaskManager.getActiveTasks();
        const mgmtTasks = this.state.mgmtTasks;
        const chats = this.state.chats;
        const pmChats = this.state.pmChats;
        const t = this.props.t;

        return (
            <div className="topbar clearfix">
                <div className="topbar-left">
                    <div className="logo" />
                    {this.props.isSmartView && <div className="f-logo-smartview" />}
                </div>

                <div className="topbar-right">
                    {!this.props.isSmartView && (
                        <a href="/" id="menu-button" onClick={this.props.onMenuButtonClick}>
                            <i />
                        </a>
                    )}

                    {isJWTDev() && !this.props.isSmartView ? (
                        <a
                            href="/"
                            id="rightpanel-menu-button"
                            onClick={this.props.onRightPanelButtonClick}
                        >
                            <i className="material-icons">more_vert</i>
                        </a>
                    ) : (
                        ""
                    )}

                    <a
                        href="/"
                        id="topbar-menu-button"
                        onClick={this.props.onTopbarMenuButtonClick}
                    >
                        <i className="material-icons">menu</i>
                    </a>
                    <ul className={topbarItemsClassName}>
                        <li
                            className={classNames("profile-item", {
                                "active-top-menu": this.props.activeTopbarItem === "profile"
                            })}
                        >
                            <a href="/" onClick={(e) => this.onTopbarItemClick(e, "profile")}>
                                <i className="topbar-icon material-icons">person</i>
                                <span className="topbar-item-name">{t("gen_userMenu")}</span>
                            </a>
                            <ul className="ultima-menu animated fadeInDown">
                                <div
                                    className={
                                        "f-profile-user" +
                                        (hasPermission(Permissions.view, Features.profile)
                                            ? " f-profile-user-button"
                                            : "")
                                    }
                                    onClick={
                                        hasPermission(Permissions.view, Features.profile)
                                            ? () =>
                                                  ProfileDialog.displayForUUID(this.state.userUUID)
                                            : undefined
                                    }
                                >
                                    <div className="f-profile-image">
                                        <i className="material-icons f-profile-icon">person</i>
                                    </div>
                                    <div className="f-profile-name">{this.state.userName}</div>
                                    <div className="f-profile-mail">{this.state.userEmail}</div>
                                </div>
                                {this.props.isSmartView && window.innerWidth > 1023 && (
                                    <li role="menuitem">
                                        <a
                                            href="/"
                                            className="f-profile-entry"
                                            onClick={this.props.onBackToDesktopItemClick}
                                        >
                                            <i className="material-icons f-profile-icon">
                                                desktop_windows
                                            </i>
                                            <span className="f-profile-text">
                                                {this.props.t("smart_backToDesktop")}
                                            </span>
                                        </a>
                                    </li>
                                )}
                                <li role="menuitem">
                                    <a
                                        href="/"
                                        className="f-profile-entry"
                                        onClick={this.props.onChangePasswordItemClick}
                                    >
                                        <i className="material-icons f-profile-icon">vpn_key</i>
                                        <span className="f-profile-text">
                                            {" "}
                                            {this.props.t("gen_changePassword")}{" "}
                                        </span>
                                    </a>
                                </li>
                                <li role="menuitem">
                                    <a
                                        href="/"
                                        className="f-profile-entry"
                                        onClick={this.onLogoutItemClick}
                                    >
                                        <i className="material-icons f-profile-icon">
                                            power_settings_new
                                        </i>
                                        <span className="f-profile-text">
                                            {" "}
                                            {this.props.t("gen_logout")}
                                        </span>
                                    </a>
                                </li>
                                <div className="f-profile-debug-area">
                                    <li role="menuitem">
                                        <div className="f-profile-debug">
                                            <span className="f-debug">
                                                {this.props.t("user_version") + ": "}
                                                {config.VERSION}
                                            </span>
                                            <br />
                                            <span className="f-debug">
                                                {this.props.t("user_role") + ": "}
                                                {this.state.userRole
                                                    ? this.props.t("role_" + this.state.userRole)
                                                    : "N/A"}
                                            </span>
                                            <br />
                                            <span className="f-debug">
                                                ID: {this.state.userUUID}
                                            </span>
                                        </div>
                                    </li>
                                    {this.state.userRole === "fmAdmin" ||
                                    this.state.userRole === "developer" ? (
                                        <div>
                                            <li role="menuitem">
                                                <a href="/" className="f-profile-entry">
                                                    <i className="material-icons f-profile-icon">
                                                        report
                                                    </i>
                                                    <span className="f-profile-text">
                                                        {" "}
                                                        {this.props.t("gen_support")}:
                                                    </span>
                                                </a>
                                            </li>
                                            <li role="menuitem">
                                                <a
                                                    className="f-profile-entry"
                                                    href="sip:+43537290830"
                                                >
                                                    Tel: +43 5372 90830
                                                </a>
                                            </li>
                                            <li role="menuitem">
                                                <a
                                                    className="f-profile-entry"
                                                    href="mailto:support@live.dkv-mobility.com"
                                                >
                                                    Email: support@live.dkv-mobility.com
                                                </a>
                                            </li>
                                            <li role="menuitem">
                                                <a
                                                    className="f-profile-entry"
                                                    href="https://dkvlive.atlassian.net/servicedesk/customer/portal/1"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Web: Support Portal
                                                </a>
                                            </li>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </ul>
                        </li>
                        <li
                            className={classNames({
                                "active-top-menu": this.props.activeTopbarItem === "tasks"
                            })}
                        >
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.onTopbarItemClick(e, "tasks");
                                }}
                            >
                                <i
                                    className="topbar-icon material-icons"
                                    style={{color: this.state.wsStateColor}}
                                >
                                    timelapse
                                </i>
                                {this.taskCount() > 0 ? (
                                    <span
                                        className="topbar-badge animated rubberBand"
                                        style={{background: "red"}}
                                    >
                                        {this.taskCount()}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <span className="topbar-item-name">Tasks</span>
                            </a>
                            {/* Tasks Menu */}
                            <ul className="ultima-menu animated fadeInDown">
                                <div className="f-topbar-task-title">
                                    {this.props.t("gen_tasks") + ":"}
                                </div>
                                {/* Management Task list */}
                                {(this.state.userRole === "fmAdmin" ||
                                    this.state.userRole === "fmUser" ||
                                    this.state.userRole === "developer") &&
                                mgmtTasks &&
                                mgmtTasks.length > 0
                                    ? mgmtTasks.map((task) => (
                                          <li key={task.internalID} role="menuitem">
                                              <div className="f-topbar-task-item">
                                                  <div
                                                      className="f-topbar-task-item-text"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => this.onMgmtTaskClick(task)}
                                                  >
                                                      {task.createdBy}
                                                  </div>
                                                  <div className="f-topbar-task-item-date">
                                                      {t(task.taskTypeLabel)} /{" "}
                                                      {DateUtil.formatDbDate(task.createdAt, true)}
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : ""}
                                {/* System Task list */}
                                <div style={{marginTop: "1em"}} />
                                {Boolean(activeTasks && activeTasks.length) &&
                                    activeTasks
                                        .slice(0, activeTasks.length > 5 ? 4 : activeTasks.length)
                                        .map((task) => (
                                            <li key={task.id} role="menuitem">
                                                <div className="f-topbar-task-item">
                                                    {/*<ProgressSpinner*/}
                                                    {/*    className="f-task-spinner"*/}
                                                    {/*    strokeWidth="7"*/}
                                                    {/*/>*/}
                                                    <div className="f-topbar-task-item-text">
                                                        {this.props.t(task.title)}
                                                    </div>
                                                    <div className="f-topbar-task-item-date">
                                                        {DateUtil.formatDateTimeSeconds(task.start)}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                {Boolean(activeTasks && activeTasks.length > 5) && (
                                    <li role="menuitem">
                                        <div className="f-topbar-task-item">
                                            <div className="f-topbar-task-item-more">
                                                {activeTasks.length -
                                                    4 +
                                                    " " +
                                                    this.props.t("gen_tasksMore")}
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {!activeTasks || !activeTasks.length ? (
                                    <li role="menuitem">
                                        <div className="f-topbar-task-no-item">
                                            <div className="f-topbar-task-item-text">
                                                {this.props.t("gen_noActiveSysTasks")}
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {/*<li role="menuitem">*/}
                                {/*    <a href="/" onClick={(e) => {*/}
                                {/*        e.preventDefault();*/}
                                {/*        TaskManager.openSidebar();*/}
                                {/*    }}*/}
                                {/*       className="f-topbar-task-show-all">*/}
                                {/*        <i className="material-icons">timelapse</i>*/}
                                {/*        <div>{this.props.t("gen_showAll")}</div>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </li>
                        {/* Notifications Menu */}
                        <li
                            className={classNames({
                                "active-top-menu": this.props.activeTopbarItem === "notifications"
                            })}
                        >
                            <a href="/" onClick={(e) => this.onTopbarItemClick(e, "notifications")}>
                                <i
                                    className="topbar-icon material-icons"
                                    style={{color: this.state.wsStateColor}}
                                >
                                    chat
                                </i>
                                {this.countChats() > 0 ? (
                                    <span
                                        className="topbar-badge animated rubberBand"
                                        style={{background: "red"}}
                                    >
                                        {this.countChats()}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <span className="topbar-item-name">Notifications</span>
                            </a>
                            <ul className="ultima-menu animated fadeInDown">
                                <div className="f-topbar-task-title">
                                    {this.props.t("gen_chats") + ":"}
                                </div>
                                {chats && chats.length > 0
                                    ? chats.map((chat) => (
                                          <li key={chat.refID + Math.random()} role="menuitem">
                                              <div className="f-topbar-task-item">
                                                  <div
                                                      className="f-topbar-task-item-text"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => this.onChatClick(chat)}
                                                  >
                                                      <i
                                                          className="material-icons"
                                                          style={{verticalAlign: "bottom"}}
                                                      >
                                                          {chat.reference === "order"
                                                              ? "shop"
                                                              : "assignment"}
                                                      </i>
                                                      {chat.refID} / {chat.creUserName}
                                                  </div>
                                                  <div className="f-topbar-task-item-date">
                                                      {DateUtil.formatDbDate(chat.creDat, true)}
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : ""}
                                {pmChats && pmChats.length > 0
                                    ? pmChats.map((pmChat) => (
                                          <li key={pmChat.refID + Math.random()} role="menuitem">
                                              <div className="f-topbar-task-item">
                                                  <div
                                                      className="f-topbar-task-item-text"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => this.onChatClick(pmChat)}
                                                  >
                                                      <i
                                                          className="material-icons"
                                                          style={{verticalAlign: "bottom"}}
                                                      >
                                                          {pmChat.reference === "pvm-order"
                                                              ? "shop"
                                                              : "assignment"}
                                                      </i>
                                                      {pmChat.refID} / {pmChat.creUserName}
                                                  </div>
                                                  <div className="f-topbar-task-item-date">
                                                      {DateUtil.formatDbDate(pmChat.creDat, true)}
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : ""}
                                {this.countChats() === 0 ? (
                                    <li role="menuitem">
                                        <div className="f-topbar-task-no-item">
                                            <div className="f-topbar-task-item-text">
                                                {this.props.t("gen_noNewChats")}
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    ""
                                )}
                            </ul>
                        </li>
                        {/* <li
                            className={classNames("search-item", {
                                "active-top-menu": this.props.activeTopbarItem === "search"
                            })}
                            onClick={(e) => this.onTopbarItemClick(e, "search")}
                        >
                            <span className="md-inputfield">
                                <InputText type="text" />
                                <label>Search</label>
                                <i className="topbar-icon material-icons">search</i>
                            </span>
                        </li> */}
                    </ul>
                    <AppMode>jwt = this.state.jwt</AppMode>
                    <SessionLanguageSelector />
                    <div className="topbar-info">
                        <div className="topbar-info-container">
                            <div className="topbar-info-test">! Testumgebung !</div>
                            {isJWTDev() && (
                                <div className="topbar-info-dev">
                                    <div className="topbar-dev-title">
                                        Developer-Account - Viewing as:
                                    </div>
                                    <div className="topbar-dev-user">
                                        <div>User:</div>
                                        <div className="topbar-dev-value">
                                            {this.state.userName}
                                        </div>
                                    </div>
                                    <div className="topbar-dev-role">
                                        <div>Role:</div>
                                        <div className="topbar-dev-value">
                                            {this.state.userRole}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.store.unsubscribe();
        if (this.socket) this.socket.close();
        if (this.interval) clearInterval(this.interval);
    }
}

export default translate("fleet")(AppTopbar);
